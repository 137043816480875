import React from 'react';
import './Header.css';
import main from './picture/mp.png';


const Header = () => {

  return (
    
    <div className='gpt3__header section__padding' id='Home'>
      <div className='gpt3__header-content'>
          <h1 className='gradient__text'>
          Hello!
          </h1>
          <h2 className='gradient__text'>
            
            I'm mohamed pegasus
          </h2>
          <p>As A full stack developer Interested in web design, web development, wordpress websites and ecommerce websites
          and i used to design websites by react in frontend and laravel in backend with mysql database
          </p>
          <p>What are the services that I can provide to the client?
            <p>1 - design responsive website across all devices</p>
            <p>2 - design WordPress site</p>
            <p>3 - design Full E-Commerce site with React.JS (frontend, backend and database)</p>
            <p>4 - design Full E-Commerce site with Laravel (frontend, backend and database)</p>
            <p>5 - design Full website with React.JS (frontend, backend and database)</p>
            <p>6 - design Full website with Laravel (frontend, backend and database)</p>
            <p>7 - design E-Commerce WordPress site</p>
            <p></p>8 - design responsive website with HTML, CSS and JavaScript (frontend)</p>
          
      </div>
      <div className='gpt3__header-image'>
            <img src={main}/>
      </div> 
      
    </div>
  )
}

export default Header;

