import React from 'react';
import './Footer.css';
import { AiFillGithub } from "react-icons/ai";
import { AiFillLinkedin } from "react-icons/ai";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { AiFillMail } from "react-icons/ai";
import { Link} from 'react-router-dom'
import {FaWhatsapp} from "react-icons/fa";
import ReactWhatsapp from 'react-whatsapp';


const Footer = () => {
  return (
    <div className='gpt3__footer section__padding'>
      <div className='gpt3__footer-heading'>
        <h1 className='gradient__text'> mohamed pegasus</h1>
      </div>
      <div className='gpt3__footer-btn'>
        <p className='gradient__text'> Put your goal at the stars, because if you fall, you will fall on the moon</p>
      </div>
      <div className='gpt3__footer-links'>
          <div className='gpt3__footer-links_logo'>
            <h1 className='gradient__text'>mp</h1>
          </div>
          
          <div className='gpt3__footer-links-div'>
              <h4>links</h4>
            <div className='pageslink'>
            <p>
            < Link to='/' className='link'> Home</Link>
            </p>
            <p>
            < Link to='/skills' className='link'> my skills</Link>
            </p>
            <p>
            < Link to='/Service' className='link'> my Services</Link>
            </p>
            <p>
            < Link to='/Blogs' className='link'> My works</Link>
            </p>
            <p>
            < Link to='/contact' className='link'>Contact me</Link>
            </p>
            </div>
          </div>
          <div className='gpt3__footer-links-div'>
              <h4>contact me</h4>
                <div className='mylinks'>
                  <a href='https://github.com/MohamedPegasus'>
                      <AiFillGithub size='50px'/>
                    </a>
                    <a href='https://www.linkedin.com/in/mohammed-pegasus-856918261/'>
                      <AiFillLinkedin size='50px'/>
                    </a>
                      <div>
                          <ReactWhatsapp number='+20 1006706474' className='whatsapp'>
                            <FaWhatsapp size='50px'/>
                            </ReactWhatsapp>
                      </div>
                </div>
          </div>
      </div>
      <div className='last'>
      <p className='gradient__text'>&copy;</p>
      <p className='gradient__text'>www.mohamedpegasus.com</p>
      <p className='gradient__text'>Email: info@mohamedpegasus.com</p>
      </div>
      
    </div>
  )
}

export default Footer