import React from 'react'
import  Connect  from "../components/connection/Connect";
import Navbar from '../components/navbar/Navbar';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';

const Contact = () => {
return (
    <div className="gradient__bg">
        < Navbar />
        < Header />
        <Connect />
        <Footer />
    </div>
)
}

export default Contact;
