import React from 'react';
import './Artical.css';
import webdesign from './pictures/web design (12).jpg'
import idesign from './pictures/web design (3).jpg'
import idesignn from './pictures/web design (7).jpg'

const Artical = () => {
    return (
        <div className='artical_section'>
        <div className='artical_part'>
            <div className='artical_small_part'>
                <img src={webdesign}/>
                <div className='atical_content'>
                    <h1> I'm mohamed pegasus</h1>
                    <p>As A full stack developer Interested in web design, web development, wordpress websites and ecommerce websites
                    and i used to design websites by react in frontend and laravel in backend with mysql database
                    </p>
                </div>
            </div>
            <div className='artical_small_part'>
                <img src={idesign}/>
                <div className='atical_content'>
                    <h1>My goal</h1>
                    <p>My goal is to provide distinguished services in web programming
                        to clients and to build long-term relationships
                        with existing clients and continuous development that 
                        will help me in obtaining new clients.</p>
                </div>
            </div>
            <div className='artical_small_part'>
                <img src={idesignn}/>
                <div className='atical_content'>
                    <h1>vision</h1>
                    <p>Continuous work, permanent development, 
                        and work to acquire new skills that enable 
                        me to find a unique competition area</p>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Artical;
