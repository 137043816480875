import React from 'react'
import Features from "../components/features/Features";
import Navbar from '../components/navbar/Navbar';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
const Skills = () => {
return (
    <div className="gradient__bg">
        < Navbar />
        < Header />
        <Features />
        <Footer />
    </div>
)
}

export default Skills
