import React from 'react';
import './App.css';
import {BrowserRouter as Router,  Routes as Switch, Route} from 'react-router-dom'
import Home from './page/Home';
import Skills from './page/Skills';
import Service from './page/Service';
import Contact from './page/Contact';
import Blogs from './page/Blogs';

function App() {
  
  return (
    <Router>
    <div className="App">
      <Switch>
        <Route exact path='/' element={<Home />}></Route>
        <Route  path='/Skills' element={<Skills />}></Route>
        <Route  path='/Service' element={<Service />}></Route>
        <Route  path='/Blogs' element={<Blogs />}></Route>
        <Route  path='/Contact' element={<Contact />}></Route>
      </Switch>
    </div>
    </Router>
  );
}

export default App;
