import React from 'react';
import './Services.css';

const Services = () => {


    return (
        <div className='gpt3__services' id='Services'>
                <h1 className='gradient__text'> my services</h1>
                <div className='gpt3__services-padding'>
                    <div className='gpt3__services-padding-serv'>
                    <h2>web design</h2>
                    <div className='icons'>
                        <i class="fa-solid fa-laptop"></i>
                    </div>
                    <p> I can design websites and front-ends with accuracy, professionalism, interactiveness and proportional to all devices</p>
                    </div>
                    <div className='gpt3__services-padding-serv'>
                    <h2>web development</h2>
                    <div className='icons'>
                        <i class="fa-solid fa-code"></i>
                    </div>
                    <p>As a full stack developer I can design complete websites ready to work directly</p>
                    </div>
                    <div className='gpt3__services-padding-serv'>
                    <h2>ecommerce site</h2>
                    <div className='icons'>
                        <i class="fa-solid fa-cart-shopping"></i>
                    </div>
                    <p>I design e-commerce websites in all its sections with high professionalism and I will attach a design for e-commerce websites in the blog</p>
                    </div>
                    <div className='gpt3__services-padding-serv'>
                    <h2>wordpress site</h2>
                    <div className='icons'>
                        <i class="fa-brands fa-wordpress"></i>
                    </div>
                    <p>I design wordpress websites in all its sections with high professionalism and I will attach a design for wordpress websites in the blog</p>
                    </div>
                    <div className='gpt3__services-padding-serv'>
                    <h2>full stack developer</h2>
                    <div className='icons'>
                        <i class="fa-solid fa-laptop-code"></i>
                    </div>
                    <p> i can design a website frontend, backend and database </p>
                    </div>
                </div>
        </div>
    )
}


export default Services;