import React from 'react';
import './MyServices.css';
import { useState } from 'react';



function MServices() {
    const [ section, setSection] = useState(false);
    const showSection = ()=>{
        
        if(window.scrollY >= 1350){
            setSection(true);
        }else{
            setSection(false);
        }
    };
    window.addEventListener('scroll', showSection);
  return (
    <div className='sections'>
        <div class="title">
                <h1>my Services</h1>
            </div>
            <div className={section ? 'section active' :'section'}>
                <div class="icons">
                    <i class="fa-solid fa-code"></i>
                </div>
                <h1>web development</h1>
                <p>I can design and development your website landing page portfolio in perfect time </p>
            </div>
            <div className={section ? 'section active' :'section'}>
                <div class="icons">
                    <i class="fa-solid fa-laptop-code"></i>
                </div>
                <h1>web design </h1>
                <p>I can design websites to suit your needs and interests with high efficiency and reasonable competitive price</p>
            </div>
            <div className={section ? 'section active' :'section'}>
                <div class="icons">
                    <i class="fa-solid fa-laptop"></i>
                </div>
                <h1>responsive design</h1>
                <p>you will get responsive design on pc, laptop, tap and mobile.</p>
            </div>
        </div>
  )
}

export default MServices
