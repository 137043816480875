import React from 'react'
import Services from '../components/services/Services'
import Navbar from '../components/navbar/Navbar'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'

const Service = () => {
return (
    <div className="gradient__bg">
        < Navbar />
        < Header />
        <Services />
        <Footer />
    </div>
)
}

export default Service;

