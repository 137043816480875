import React from "react";
import  Navbar  from '../components/navbar/Navbar';
import   Header from '../components/header/Header'
import   Footer from '../components/footer/Footer'
import  Artical  from "../components/artical/Artical";
import MServices from "../components/myservice/MyServices";

const Home = () => {
    
    return(
        <div className="gradient__bg">
        < Navbar />
        < Header />
        <Artical />
        <MServices/>
        <Footer />
    </div>
    )
}

export default Home;