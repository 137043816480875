import React from 'react';
import './Connect.css';
import { Button } from 'react-bootstrap';
import emailjs from 'emailjs-com';



const Brand = () => {
  function sendEmail(e) {
  e.preventDefault();
  emailjs.sendForm('service_k1ilt06','template_tp5vvhs',e.target,'vqcfFkADYaK04drdF')
  .then(res=>{
    console.log(res);
  })
  alert('your Email has been send Successfully')
  .catch(err=>console.log(err));
}
  return (
    
    <div className='form' id='connect' >
        
        <form  onSubmit={sendEmail}>
          <h1 className='gradient__text'>Contact me</h1>
          <label> Your Name</label>
          <input type='text' name='name'></input>
          <label>Email</label>
          <input type='email' name='email'></input>
          <label> Subject</label>
          <input type='text' name='subject'></input>
          <label>Details</label>
          <textarea rows='6' placeholder='type a short massage here' name='message'></textarea> 
          <br/>
          <Button variant="outline-success" type='submit' value='send' >submit</Button>
        </form>
    </div>
    
  )
}

export default Brand;