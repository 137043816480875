import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCube, Pagination } from "swiper";
import { EffectCoverflow } from "swiper";

import "swiper/css";
import "swiper/css/effect-cube";
import "swiper/css/pagination";
import './Blog.css'
import pic1 from './pictures/project1/Screenshot 2023-01-07 003222.png'
import pic2 from './pictures/project1/screencapture-localhost-3001-E-commerce-React-2023-01-07-00_36_19.png'
import pic3 from './pictures/project1/screencapture-localhost-3001-E-commerce-React-pricing-2023-01-07-00_35_58.png'
import pic4 from './pictures/project1/Screenshot 2023-01-07 003338.png'
import pic5 from './pictures/project1/Screenshot 2023-01-07 003507.png'
import pic6 from './pictures/project1/Screenshot 2023-01-07 003222.png'
import react1 from './pictures/project2/react1.png'
import react2 from './pictures/project2/react2.png'
import react3 from './pictures/project2/react3.png'
import react4 from './pictures/project2/react4.png'
import react5 from './pictures/project2/react5.png'
import react6 from './pictures/project2/react6.png'
import react7 from './pictures/project2/react7.png'
import react8 from './pictures/project2/react8.png'
import react9 from './pictures/project2/react9.png'
import react10 from './pictures/project2/react10.png'


function Blog() {
return (
    <div className='blog_main' id='Blog'>
        <h1 className='gradient__text'>my works</h1>
        <div className='blog_main_work'>
            <div>
                <h2 className='gradient__text'>crazy food Resturant</h2>
            </div>
            
            <>
            <Swiper
        effect={"cube"}
        grabCursor={true}
        cubeEffect={{
            shadow: true,
            slideShadows: true,
            shadowOffset: 20,
            shadowScale: 0.94,
            
        }}
        
        pagination={true}
        modules={[EffectCube, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src={pic1} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={pic2} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={pic3}/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={pic4} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={pic5} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={pic6} />
        </SwiperSlide>
      </Swiper>
        </>
        </div>
        <div className='blog_main_worksf'>
            <div>
                <h2 className='gradient__text'>react web site</h2>
            </div>
            
            <>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        loop={true}
        pagination={true}
        modules={[EffectCoverflow, Pagination]}
        className="cardSwiper"
      >
        <SwiperSlide>
          <img src={react1} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={react2} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={react3} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={react4} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={react5} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={react6} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={react7} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={react8} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={react9} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={react10} />
        </SwiperSlide>
      </Swiper>
        </>
        </div>
    </div>
)
}

export default Blog;
